<template>
  <b-card no-body>
    <b-card-header>
      <div class="card-header">
        <h4>
          {{ title }}
        </h4>
        <b-button
          variant="primary"
          @click="$refs.refInputEl.click()"
        >
          <input
            ref="refInputEl"
            type="file"
            class="d-none"
            accept=".xlsx,.xls,.csv"
            @input="handleCsvUploaded"
          />
          <span class="d-none d-sm-inline">
            {{ $t('importFile') }}
          </span>
        </b-button>
      </div>
    </b-card-header>
    <b-card-body class="p-0">
      <div v-if="onRequest && approvedProducts.length">
        <hr class="divider" />
        <b-card-body>
          <trading-products-table
            :products="approvedProducts"
            :maring="margin"
            :currency="currency"
          />
        </b-card-body>
      </div>
      <div v-if="draggableProducts.length">
        <hr class="divider" />
        <draggable
          v-model="draggableProducts"
          class="p-2"
          :options="{disabled : isProductUpdateActive}"
        >
          <trading-product-item
            v-for="product in draggableProducts"
            :key="product.id"
            :on-request="onRequest"
            :product="product"
            @remove="removeProduct"
            @update-start="startProductUpdating"
          >
            <template
              v-if="isProductUpdateActive && productToUpdate.id === product.id"
              slot="product-edit"
            >
              <trading-offers-new-product
                :is-existed-product="isProductUpdateActive"
                :is-product-from-item="isProductFromItem"
                :product-to-edit="productToUpdate"
                :currency="currency"
                :on-request="onRequest"
                :created-products-ids="createdProductsIds"
                @remove-product="removeProduct"
                @create-product="createProduct"
                @update-product="updateProduct"
              />
            </template>
          </trading-product-item>
        </draggable>
      </div>
      <div
        v-if="showProductEditing"
      >
        <hr class="divider" />
        <div class="p-2">
          <trading-offers-new-product
            :currency="currency"
            :on-request="onRequest"
            :created-products-ids="createdProductsIds"
            @create-product="createProduct"
            @remove-product="closeEditing"
            @update-product="updateProduct"
            @close-editing="closeEditing"
          />
        </div>
      </div>

      <hr class="divider" />
      <div class="p-2">
        <b-button
          v-if="!onRequest"
          variant="primary"
          @click="addProduct"
        >
          {{ $t('addItem') }}
        </b-button>
      </div>
    </b-card-body>

    <import-csv
      v-if="modalVisible"
      :title="title"
      :mode="'offer'"
      :import-mode="'itemsForOffer'"
      :excel-rows-full-received="excelRowsFull"
      @close-modal="modalVisible = false"
      @update-products-all="updateProductsAll"
    />
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BButton,
} from 'bootstrap-vue';
import TradingProductItem from '@/views/trading/trading-add-item/TradingProductItem.vue';
import TradingOffersNewProduct from '@/views/trading/trading-add-item/offers/TradingOffersNewProduct.vue';
import Ripple from 'vue-ripple-directive';
import TradingProductsTable from '@/views/trading/TradingProductsTable.vue';
import draggable from 'vuedraggable';
import readXlsxFile from 'read-excel-file';
import ImportCsv from '@/views/modals/ImportCsv.vue';

export default {
  components: {
    BCard,
    BButton,
    BCardBody,
    BCardHeader,
    TradingProductItem,
    TradingOffersNewProduct,
    TradingProductsTable,
    draggable,
    ImportCsv,
  },
  directives: {
    Ripple,
  },
  props: {
    products: {
      type: Array,
      default: (() => []),
    },
    margin: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: '',
    },
    currency: {
      type: Number,
      default: 0,
    },
    status: {
      type: String,
      default: '',
    },
    onRequest: {
      type: Boolean,
      default: false,
    },
    isProductFromItem: {
      type: Boolean,
      default: false,
    },
    createdProductsIds: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    isProductUpdateActive: false,
    productToUpdate: null,
    showProductEditing: false,
    selectedCategory: null,
    draggableProducts: [],
    modalVisible: false,
    excelRowsFull: [],
  }),
  computed: {
    approvedProducts() {
      return this.products.filter(product => product.price > 0);
    },
  },
  watch: {
    products(value) {
      this.draggableProducts = value;
      if (this.onRequest) {
        const isNotApproved = value.filter(product => !product.price && product.available);
        const declined = value.filter(product => !product.price && !product.available);
        this.draggableProducts = [...isNotApproved, ...declined];
      }
    },
    draggableProducts(value) {
      if (!this.onRequest) {
        this.$emit('update-products', { data: value });
      }
    },
  },
  async created() {
    if (this.$route.params.id && this.$route.params.id === 'new') {
      this.showProductEditing = true;
    }
  },
  methods: {
    createProduct(data) {
      this.$emit('update-products', {
        data: !data.itemIdToRemove ? [...this.products, data.data] : [...this.products, data.data].filter(el => el.id !== data.itemIdToRemove),
        needPush: true,
      });
      this.showProductEditing = false;
      this.isProductUpdateActive = false;
    },
    updateProduct(data) {
      const productsWithoutUpdated = this.products.filter(product => product.id !== data.id);
      this.$emit('update-products', { data: [...productsWithoutUpdated, data] });
      this.isProductUpdateActive = false;
    },
    updateProductsAll(updatedProducts) {
      const updatedIds = updatedProducts.map(updatedProduct => updatedProduct.id);
      const productsWithoutUpdated = this.products.filter(product => !updatedIds.includes(product.id));
      this.$emit('update-products', { data: [...productsWithoutUpdated, ...updatedProducts] });
      this.isProductUpdateActive = false;
    },
    removeProduct(id) {
      if (!this.onRequest) {
        const updatedProducts = this.products.filter(product => product.id !== id);
        this.$emit('update-products', { data: updatedProducts });
      } else {
        this.closeEditing();
      }
    },
    startProductUpdating(id) {
      if (this.isProductUpdateActive && this.productToUpdate && id === this.productToUpdate.id) {
        this.isProductUpdateActive = false;
        this.productToUpdate = null;
        return;
      }
      this.isProductUpdateActive = true;
      this.productToUpdate = this.products.find(item => item.id === id);
    },
    closeEditing() {
      this.showProductEditing = false;
      this.isProductUpdateActive = false;
      this.productToUpdate = null;
    },
    addProduct() {
      this.closeEditing();
      this.showProductEditing = true;
    },
    async handleCsvUploaded() {
      // here - uploadDocuments()

      const xlsxFile = this.$refs.refInputEl.files ? this.$refs.refInputEl.files[0] : null;

      this.excelRowsFull = await readXlsxFile(xlsxFile);

      this.modalVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../../@core/scss/base/core/colors/palette-variables';

.divider {
  margin: 0;
}

.card-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
</style>
