<template>
  <div>
    <b-card no-body>
      <b-card-body>
        <b-form-textarea
          :value="message"
          :placeholder="$t('placeholder')"
          @change="$emit('update:message', $event)"
        />
        <b-row
          v-if="itemType === 'offer'"
          class="d-flex align-items-center justify-content-end mt-3"
        >
          <b-col
            cols="12"
            xl="7"
            md="7"
          >
            <span>{{ $t('fullOfferCost') }}:</span>
            <span class="ml-2">
              {{ currency | currencySymbol }}{{ fullCost }}
            </span>
          </b-col>
        </b-row>

        <b-tooltip
          v-if="isSaveDisabled && !isItemEditable"
          :title="dtnTooltip"
          target="saveButton"
          triggers="hover"
        />
        <div
          id="saveButton"
          class="mt-2 mb-1"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            block
            :disabled="isSaveDisabled"
            @click="save"
          >
            <feather-icon icon="DiscIcon" />
            {{ $t('save') }}
          </b-button>
        </div>

        <b-tooltip
          v-if="isSaveDisabled && !isItemEditable"
          :title="dtnTooltip"
          target="saveAndPublishButton"
          triggers="hover"
        />
        <div
          id="saveAndPublishButton"
          class="mb-1"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            block
            :disabled="isSaveDisabled"
            @click="publish"
          >
            <feather-icon icon="FileTextIcon" />
            {{ $t('saveAndPublish') }}
          </b-button>
        </div>

        <b-tooltip
          v-if="!isItemRemovable"
          :title="dtnTooltip"
          target="deleteButton"
          triggers="hover"
        />
        <div
          v-if="!isNewMode"
          id="deleteButton"
          class="delete-button-div mt-1"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            :disabled="!isItemRemovable"
            class="mt-1"
            variant="outline-danger"
            block
            @click="handleDeleteClick"
          >
            {{ $t(itemType === 'offer' ? 'deleteOffer' : 'deleteRequest') }}
          </b-button>
        </div>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-secondary"
          class="mt-2"
          block
          @click="cancel"
        >
          {{ $t('cancel') }}
        </b-button>
      </b-card-body>
    </b-card>

    <confirm-modal
      v-if="isItemRemovable && !isNewMode"
      :modal-visible="modalVisible"
      :modal-message="String($t(itemType === 'offer' ? 'deleteOfferConfirmMessage' : 'deleteRequestConfirmMessage', {id: itemId || null}))"
      ok-text="Yes, delete"
      cancel-text="No, cancel"
      :is-ok-button-spinning="isOkButtonSpinning"
      @modal-hidden="modalVisible = false"
      @yes-clicked="deleteItem"
      @no-clicked="modalVisible = false"
    />
  </div>
</template>

<script>
import {
  BCard, BCardBody, BRow, BCol, BFormTextarea, BButton, BTooltip,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import confirmModal from '@/views/apps/components/modals/confirmModal.vue';

export default {
  components: {
    BTooltip,
    confirmModal,
    BCard,
    BCardBody,
    BRow,
    BCol,
    BFormTextarea,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    isNewMode: {
      type: Boolean,
      default: false,
    },
    itemType: {
      type: String,
      default: 'offer',
    },
    itemId: {
      type: [Number, String],
      default: null,
    },
    message: {
      type: String,
      default: '',
    },
    isSaveDisabled: {
      type: Boolean,
      default: false,
    },
    isItemEditable: {
      type: Boolean,
      default: true,
    },
    isItemRemovable: {
      type: Boolean,
      default: false,
    },
    isOkButtonSpinning: {
      type: Boolean,
      default: false,
    },
    currency: {
      type: Number,
      default: 0,
    },
    fullCost: {
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      modalVisible: false,
    };
  },
  computed: {
    dtnTooltip() {
      if (this.itemType === 'offer') {
        return this.$t('offerUsedInDealOrRequest');
      }
      return this.$t('dealOrOfferCreatedFromRequest');
    },
  },
  methods: {
    save() {
      this.$emit('save');
    },
    publish() {
      this.$emit('publish');
    },
    cancel() {
      this.$emit('cancel');
    },
    handleDeleteClick() {
      this.modalVisible = true;
    },
    deleteItem() {
      this.$emit('delete');
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/@core/scss/base/core/colors/palette-variables.scss';
</style>
