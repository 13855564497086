<template>
  <b-modal
    ref="excelModal"
    :visible="true"
    :title="title"
    size="xl"
    class="modal-class"
    modal-class="modal-class"
    centered
    hide-footer
    @hide="close"
  >
    <div class="d-block text-center mt-3">
      <div class="main-div">
        <div class="d-flex justify-content-end">
          <b-form-checkbox
            v-model="isTableContainsHeaders"
            class="mb-2"
          >
            {{ $t('tableContainsHeaders') }}
          </b-form-checkbox>
        </div>

        <!-- table -->
        <div class="table-container">
          <vue-good-table
            ref="vueGoodTable"
            :columns="excelColumns"
            :rows="excelRows"
            :row-style-class="''"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm }"
            :select-options="{
              enabled: true,
              selectOnCheckboxOnly: false,
              selectAllByPage: false,
              selectionInfoClass: 'custom-class',
              selectionText: 'rows selected',
              clearSelectionText: 'clear',
              disableSelectInfo: true,
              selectAllByGroup: true,
            }"
            :sort-options="{
              enabled: true,
            }"
            :pagination-options="{
              enabled: true,
              perPage: tableParams.currentPerPage,
              mode: 'records',
            }"
            style-class="vgt-table condensed"
            @on-page-change="handlePageChange"
            @on-per-page-change="handlePageChange"
            @on-selected-rows-change="handleSelectionChanged"
          >
            <template
              slot="table-column"
              slot-scope="props"
            >
              <div class="select-div">
                <b-form-select
                  v-if="showsSelectColumns.includes(props.column.field)"
                  v-model="selectObjects[props.column.field]"
                  placeh
                  :options="titleSelectOptions"
                  class="column-select"
                  :class="{'not-selected': !selectObjects[props.column.field]}"
                />
              </div>

              <div>
                <span>
                  {{ props.column.label }}
                </span>
              </div>
            </template>
            <template
              slot="pagination-bottom"
            >
              <div />
            </template>
          </vue-good-table>
        </div>

        <div>
          <div class="d-flex justify-content-end flex-wrap text-style">
            <div class="d-flex align-items-center">
              <span class="d-flex">
                {{ $t('itemsPerPage') }}:
              </span>
              <b-form-select
                v-model="tableParams.currentPerPage"
                :options="['10','25','50','100']"
                class="mx-1 d-flex form-select"
                @input="value => $refs.vueGoodTable.perPageChanged({currentPerPage: value})"
              />
            </div>

            <div class="d-flex align-items-center ml-2">
              <span class="text-nowrap">
                {{ tableParams.currentPerPage * (tableParams.currentPage - 1) + 1 }}
                - {{ tableParams.currentPerPage * tableParams.currentPage >= tableParams.totalRows ? tableParams.totalRows : tableParams.currentPerPage * tableParams.currentPage }}
                {{ $t('of') }} {{ tableParams.totalRows }} {{ $t('rows') }}
              </span>
            </div>

            <div class="d-flex align-items-center ml-3">
              <b-pagination
                :value="1"
                :total-rows="tableParams.totalRows"
                :per-page="tableParams.currentPerPage"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1"
                @input="value => $refs.vueGoodTable.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <b-row>
        <b-col>
          <b-button
            class="mt-3"
            variant="outline-secondary"
            block
            @click="close"
          >
            {{ $t('cancel') }}
          </b-button>
        </b-col>
        <b-col>
          <b-button
            class="mt-3"
            variant="outline-primary"
            block
            @click="handleImportProductsClick"
          >
            <b-spinner
              v-if="isImportProductsLoading"
              class="align-middle mr-1 button-spinner"
            />
            {{ $t('importSelectedProducts') }}
          </b-button>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
import {
  BButton,
  BModal,
  BCol,
  BRow,
  BPagination,
  BFormSelect,
  BFormCheckbox,
  BSpinner,
} from 'bootstrap-vue';
import { VueGoodTable } from 'vue-good-table';
import Ripple from 'vue-ripple-directive';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import i18n from '@/libs/i18n';
import readXlsxFile from 'read-excel-file';
import 'vue-good-table/dist/vue-good-table.css';

export default {
  components: {
    BSpinner,
    BRow,
    BCol,
    BButton,
    BModal,
    BPagination,
    BFormSelect,
    BFormCheckbox,
    VueGoodTable,
  },
  directives: {
    Ripple,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    products: {
      type: Array,
      default: (() => []),
    },
    addBtnDisabled: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: 'request',
    },
    importMode: {
      type: String,
      default: 'product',
    },
    excelRowsFullReceived: {
      type: Array,
      default: (() => []),
    },
  },
  data: () => ({
    selectedCategory: null,
    isImportProductsLoading: false,
    selected: [],
    selectObjects: {},
    selectedRowIndexes: [],
    excelRowsFull: [],
    tableParams: {
      currentPage: 1,
      currentPerPage: 10,
      totalRows: 0,
      totalPages: 1,
    },
    isTableContainsHeaders: true,
    searchTerm: '',
  }),
  computed: {
    excelRows() {
      if (this.isTableContainsHeaders) {
        return this.excelRowsFull.slice(1, this.excelRowsFull.length);
      }

      return this.excelRowsFull;
    },
    excelColumns() {
      const maxArrayLength = this.excelRowsFull.reduce((maxLength, currentArray) => Math.max(maxLength, Object.values(currentArray).length), 0);

      if (this.isTableContainsHeaders) {
        return this.excelRowsFull.length ? this.preparingColumnsData(this.excelRowsFull[0], maxArrayLength) : [];
      }

      const letterHeaders = this.getLetterHeaders(maxArrayLength);

      return this.preparingColumnsData(letterHeaders);
    },
    showsSelectColumns() {
      const arr = [];

      this.excelColumns.forEach(column => {
        if (this.excelRows.some(obj => !!obj[column.field])) arr.push(column.field);
      });

      return arr;
    },
    titles() {
      const titles = this.mode === 'request' ? [
        { value: 'group_id', text: i18n.t('productGroup') },
        { value: 'name', text: i18n.t('productName') },
        { value: 'index', text: i18n.t('partNumber') },
        { value: 'count', text: i18n.t('quantity') },
        { value: 'warranty', text: i18n.t('warranty') },
        { value: 'tnv', text: i18n.t('tnVed') },
        { value: 'delivery_type', text: i18n.t('deliveryType') },
        { value: 'note', text: i18n.t('comments') },
        { value: null, text: i18n.t('notSelected') },
      ] : [
        { value: 'group_id', text: i18n.t('productGroup') },
        { value: 'name', text: i18n.t('productName') },
        { value: 'index', text: i18n.t('partNumber') },
        { value: 'tnv', text: i18n.t('tnVed') },
        { value: 'note', text: i18n.t('comments') },
        { value: 'delivery_type', text: i18n.t('deliveryType') },
        { value: 'count', text: i18n.t('quantity') },
        { value: 'price', text: i18n.t('unitPrice') },
        { value: null, text: i18n.t('notSelected') },
      ];

      if (this.mode === 'request' && this.importMode === 'itemsForStock') {
        ['count', 'warranty'].forEach(value => {
          const titleIndex = titles.findIndex(title => title.value === value);

          if (titleIndex > -1) titles.splice(titleIndex, 1);
        });
      }

      return titles;
    },
    titleSelectOptions() {
      const selectedValues = Object.values(this.selectObjects);

      return this.titles.map(item => ({ ...item, disabled: item.value && selectedValues.includes(item.value) }));
    },
    productDefaultProperties() {
      return this.mode === 'request' ? {
        price: '',
        tnved_codes: [],
        image_ids: [],
        document_ids: [],
        packing_qty: '',
        packing_net: '',
        packing_gross: '',
        packing_meas: '',
        delivery_type: 'retail',
        count: 0,
      } : {
        analog_index: '',
        analog_name: '',
        category: '',
        vat: '',
        price: '',
        image_ids: [],
        document_ids: [],
        packing_qty: '',
        packing_net: '',
        packing_gross: '',
        packing_meas: '',
        delivery_type: 'retail',
      };
    },
  },
  watch: {
    isTableContainsHeaders() {
      this.tableParams.totalRows = this.excelRows.length;

      this.initSelectObjects();
    },
  },
  async mounted() {
    if (this.$route.params.id && this.$route.params.id === 'new') {
      this.showProductEditing = true;
    }

    this.draggableProducts = this.products;

    this.excelRowsFull = this.preparingRowsData(this.excelRowsFullReceived);
    this.tableParams.totalRows = this.excelRows.length;
    this.tableParams.totalPages = Math.round(this.tableParams.totalRows / this.tableParams.currentPerPage);

    this.$nextTick(() => {
      this.initSelectedRowIndexes();
    });

    await this.$store.dispatch('products/fetch');

    this.initSelectObjects();
  },
  methods: {
    initSelectedRowIndexes() {
      this.$nextTick(() => {
        this.$refs.vueGoodTable.toggleSelectAll();

        this.selectedRowIndexes = this.$refs.vueGoodTable.selectedRows.map(el => el.originalIndex);
      });

      this.setStopPropagation();
    },
    setStopPropagation() {
      setTimeout(() => {
        const selects = document.querySelectorAll('.custom-select');

        function eventSelectClick(event) {
          event.stopPropagation();
        }

        selects.forEach(select => select.addEventListener('click', eventSelectClick, true));
      }, 10);
    },
    async handleCsvUploaded() {
      // here - uploadDocuments()

      const xlsxFile = this.$refs.refInputEl.files ? this.$refs.refInputEl.files[0] : null;

      this.excelRowsFull = await readXlsxFile(xlsxFile);
    },
    async handleImportProductsClick() {
      const newProducts = [];
      const keys = Object.keys(this.selectObjects);

      this.selectedRowIndexes.forEach(rowIndex => {
        const product = JSON.parse(JSON.stringify(this.productDefaultProperties));

        keys.forEach(key => {
          if (this.selectObjects[key]) {
            if (this.selectObjects[key] === 'group_id') {
              const productGroup = this.$store.state.products.groups.find(group => group.name === this.excelRows[rowIndex][key]);

              if (productGroup) {
                product[this.selectObjects[key]] = productGroup.id;
              } else {
                const defaultGroup = this.$store.state.products.groups.find(group => group.name === 'DEFAULT');

                if (defaultGroup) {
                  product[this.selectObjects[key]] = defaultGroup.id;
                } else {
                  product[this.selectObjects[key]] = this.$store.state.products.groups[0].id;
                }
              }
            } else {
              product[this.selectObjects[key]] = this.excelRows[rowIndex][key];
            }
          }
        });

        newProducts.push(product);
      });

      this.isImportProductsLoading = true;

      const emitProducts = [];
      const promises = [];

      if (['itemsForWarehouse', 'itemsForStock', 'itemsForOffer', 'itemsForRequest'].includes(this.importMode)) {
        await this.$http.put('/v1/items/storeMultiple', {
          items: newProducts.map(product => (
            {
              name: product.name,
              description: product.note,
              price: product.price,
              index: product.index,
              count: product.count,
              unit_of_measurement: 'pcs.',
              tnv: product.tnv,
              package_type: 'box',
            }
          )),
        }).then(({ data }) => {
          if (['itemsForWarehouse', 'itemsForStock'].includes(this.importMode)) {
            this.$emit('fetch-items');
          } else {
            const products = data.data.map(item => ({
              isNeedSave: true,
              id: item.id,
              analog_index: null,
              analog_name: null,
              category: null,
              vat: null,
              price: item.last_price?.price,
              image_ids: [],
              document_ids: [],
              packing_qty: null,
              packing_net: null,
              packing_gross: null,
              packing_meas: null,
              delivery_type: 'retail',
              country: item.country,
              total_sum: 0,
              warranty: 0,
              tnved_codes: null,
              available: true,
              images: [],
              documents: [],
              name: item.name,
              index: item.index,
              count: item.stock?.count,
              group_id: 1,
              note: item.description,
              tnv: item.tnv_codes[0]?.code,
            }));

            this.$emit('update-products-all', products);
          }
        }).finally(() => {
          this.isImportProductsLoading = false;

          this.$toast({
            component: ToastificationContent,
            props: {
              title: i18n.t('productsImportedSuccessfully'),
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          });
        });
      } else {
        newProducts.forEach(newProduct => {
          promises.push(this.$http.post('/products', newProduct));
        });

        await Promise.all(promises).then(responses => {
          responses.forEach(({ data }) => {
            if (data.status) {
              emitProducts.push(data.data.product);
            }
          });

          this.$emit('update-products-all', emitProducts);

          this.$toast({
            component: ToastificationContent,
            props: {
              title: i18n.t('productsImportedSuccessfully'),
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          });
        }).finally(() => {
          this.isImportProductsLoading = false;
        });
      }
    },
    preparingRowsData(arrayOfArrays) {
      return arrayOfArrays.map(array => {
        const obj = {};

        array.forEach((elem, index) => {
          obj[index] = elem ? String(elem) : elem;
        });

        return obj;
      });
    },
    preparingColumnsData(columnHeaders, maxArrayLength = 0) {
      const array = Array.isArray(columnHeaders) ? columnHeaders : Object.values(columnHeaders);

      if (array.length < maxArrayLength) {
        array.push(...Array(maxArrayLength - array.length).fill('-'));
      }

      return array.map((elem, index) => ({
        label: elem || '-',
        field: `${index}`,
        width: '170px',
        thClass: '',
        sortable: true,
      }));
    },
    handlePageChange(params) {
      this.tableParams.currentPage = params.currentPage;
      this.tableParams.prevPage = params.prevPage;
      this.tableParams.currentPerPage = params.currentPerPage;
      this.tableParams.totalPages = params.total;
      this.tableParams.totalRows = this.excelRows.length;
    },
    handleSelectionChanged({ selectedRows }) {
      this.selectedRowIndexes = selectedRows.map(el => el.originalIndex);
    },
    getLetterHeaders(columnsNumber) {
      const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      const combinations = [];

      // eslint-disable-next-line no-plusplus
      for (let i = 1; i <= columnsNumber; i++) {
        let num = i;
        let combination = '';

        while (num > 0) {
          const remainder = (num - 1) % 26;
          combination = letters.charAt(remainder) + combination;
          num = Math.floor((num - 1) / 26);
        }

        combinations.push(combination);
      }

      return combinations;
    },
    initSelectObjects() {
      const obj = {};

      this.excelColumns.filter(excelColumn => this.showsSelectColumns.includes(excelColumn.field)).forEach(column => {
        obj[String(column.field)] = this.selectObjects[String(column.field)] ? this.selectObjects[String(column.field)] : null;
      });

      this.selectObjects = obj;
    },
    close() {
      this.$emit('close-modal');
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'vue-good-table/dist/vue-good-table';

.divider {
  margin: 0;
}

.card-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.modal-class {
  background-color: #1ab7ea !important;
}

.table-container {
  width: 100%;
  overflow-x: auto;
  padding-top: 50px !important;
}

.form-select {
  width: fit-content;
}

.text-style {
  color: #4e5154 !important;
}

.custom-class {
  background-color: #1ab7ea !important;
}

::v-deep .custom-th-class {
  background-color: #1ab7ea !important;
  text-align: center !important;
}

::v-deep .pointer {
  pointer-events: all !important;
  color: #1ab7ea !important;
}

::v-deep .custom-td-class {
  background-color: green !important;
}

::v-deep .vgt-checkbox-col {
  background-color: #1ab7ea !important;
}

::v-deep .column-select {
  position: absolute;
  top: -50px;
  width: 80%;
  z-index: 1000 !important;
}

.select-div {
  z-index: 1000 !important;
}

::v-deep .vgt-responsive {
  overflow-x: unset;
}

::v-deep .not-selected {
  color: #d0d0d0;
}

::v-deep .button-spinner {
  width: 14px;
  height: 14px;
}
</style>
