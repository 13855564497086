import { render, staticRenderFns } from "./TradingRequestsNewItem.vue?vue&type=template&id=aed43718&scoped=true"
import script from "./TradingRequestsNewItem.vue?vue&type=script&lang=js"
export * from "./TradingRequestsNewItem.vue?vue&type=script&lang=js"
import style0 from "./TradingRequestsNewItem.vue?vue&type=style&index=0&id=aed43718&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aed43718",
  null
  
)

export default component.exports