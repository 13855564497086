<template>
  <div class="d-flex justify-content-between pb-2 pl-2 pr-2 flex-wrap">
    <div class="d-flex flex-wrap">
      <b-form-group
        :label="$t('quantity')"
        label-for="qty-input"
      >
        <b-row class="d-flex align-items-center">
          <b-col
            cols="10"
            md="8"
            lg="8"
          >
            <b-form-input
              id="qty-input"
              :value="qty"
              :disabled="disabled"
              type="number"
              @input="updateQty"
            />
          </b-col>
          <b-col
            cols="2"
            md="4"
            lg="4"
            class="p-0"
          >
            <span>{{ $t('pcs') }}</span>
          </b-col>
        </b-row>
      </b-form-group>
      <b-form-group
        :label="$t('unitPrice')"
        label-for="unit-price-input"
      >
        <b-row class="d-flex align-items-center">
          <b-col
            cols="10"
            md="8"
            lg="8"
          >
            <b-form-input
              id="unit-price-input"
              :value="price"
              type="number"
              @input="updatePrice"
            />
          </b-col>
          <b-col
            cols="2"
            md="4"
            lg="4"
            class="p-0"
          >
            <span>{{ currency | currencySymbol }}</span>
          </b-col>
        </b-row>
      </b-form-group>
    </div>
    <div class="d-flex align-items-center flex-wrap">
      <b-form-checkbox
        v-model="isVatActive"
        class="mr-50"
      >
        {{ $t('vat') }}
      </b-form-checkbox>
      <b-row class="d-flex align-items-center">
        <b-col
          cols="10"
          md="6"
          lg="6"
        >
          <b-form-input
            id="unit-price-input"
            :disabled="!isVatActive"
            :value="vat"
            type="number"
            @input="updateVat"
          />
        </b-col>
        <b-col
          cols="2"
          md="2"
          lg="2"
          class="p-0"
        >
          <span>%</span>
        </b-col>
      </b-row>
      <div class="d-flex flex-column align-items-end pr-2">
        <span class="pb-2">
          {{ $t('totalCost') }}
        </span>
        <h5 class="mb-0">
          {{ currency | currencySymbol }} {{ finalCost }}
        </h5>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BFormCheckbox,
} from 'bootstrap-vue';

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
  },
  props: {
    qty: {
      type: [String, Number],
      default: '',
    },
    price: {
      type: [String, Number],
      default: '',
    },
    vat: {
      type: [String, Number],
      default: '',
    },
    currency: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isVatActive: false,
  }),
  computed: {
    finalCost() {
      const costForAllQty = this.qty * this.price;
      return costForAllQty + (costForAllQty * (this.vat / 100));
    },
  },
  watch: {
    vat(value) {
      if (value) {
        this.isVatActive = true;
      }
    },
  },
  methods: {
    updatePrice(value) {
      this.$emit('update-price', value);
    },
    updateQty(value) {
      this.$emit('update-qty', value);
    },
    updateVat(value) {
      this.$emit('update-vat', value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../@core/scss/base/core/colors/palette-variables';
@import 'src/@core/scss/base/components/variables-dark';
@import "src/@core/scss/base/pages/app-invoice.scss";

.packing {
  background-color:$product-details-bg;
  &-title {
    font-weight: 600;
  }
}
</style>

<style lang="scss">
@import '../../../@core/scss/base/core/colors/palette-variables';
@import 'src/@core/scss/base/components/variables-dark';
.dark-layout {
  .packing {
    background-color: $theme-dark-body-bg;
  }
}
</style>
