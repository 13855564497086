<template>
  <b-card no-body>
    <b-card-header>
      <b-row class="w-100">
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <h4 class="d-inline-block mr-1">
            {{ header }}
          </h4>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <span
              v-if="offerId"
              class="offer-id mr-1"
            >
              {{ offerId }}
            </span>
            <slot name="status" />
          </div>
        </b-col>
      </b-row>
    </b-card-header>
    <hr class="divider" />
    <b-card-body>
      <b-row v-if="!isDeal">
        <b-col
          v-if="!isOffer"
          cols="8"
          md="4"
          lg="4"
        >
          <b-form-group
            :label="$t('title')"
            label-for="country-field"
          >
            <b-form-input
              :value="title"
              class="mb-2"
              @input="$emit('update:title', $event)"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="!isOffer"
          cols="8"
          md="4"
          lg="4"
        >
          <b-form-group
            :label="$t('countryOrCode')"
            label-for="country-field"
          >
            <v-select
              :value="countryId"
              :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
              :options="countryOptions"
              :reduce="val => val.value"
              :clearable="false"
              input-id="country-field"
              @input="$emit('update:country-id', $event)"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-else
          cols="12"
          md="4"
          lg="4"
        >
          <b-form-group
            :label="$t('countryOrCode')"
            label-for="country-field"
          >
            <v-select
              :value="countryId"
              :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
              :options="countryOptions"
              :reduce="val => val.value"
              :clearable="false"
              input-id="country-field"
              @input="$emit('update:country-id', $event)"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="isOffer"
          cols="12"
          md="4"
          lg="4"
        >
          <b-form-group
            label="Lead time"
            label-for="lead-field"
          >
            <b-form-input
              id="lead-field"
              type="number"
              :value="lead || null"
              placeholder="days"
              @change="$emit('update:lead', $event || null)"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="8"
          md="4"
          lg="4"
        >
          <b-form-group
            v-if="isOffer"
            label="Currency"
            label-for="currency-field"
          >
            <v-select
              :value="currency"
              :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
              :options="currencyOptions"
              :reduce="val => val.value"
              :clearable="false"
              input-id="currency-field"
              @input="$emit('update:currency', $event)"
            />
          </b-form-group>
          <b-form-group
            v-else
            :label="`${$t('budget')}, ${currencySymbol}`"
            label-for="budget-field"
          >
            <b-form-input
              id="budget-field"
              type="number"
              :value="budget"
              @change="$emit('update:budget', $event)"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <div
        v-else
      >
        <span>{{ $t('countryOrCode') }}:</span>
        <span class="ml-1">
          {{ defaultCountry }}
        </span>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BFormGroup, BFormInput, BCard, BCardBody, BCardHeader, BRow, BCol,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import { currencyOptions, getCurrencySymbol, USD_ID } from '@/constants/currency.js';

export default {
  components: {
    BFormGroup,
    BFormInput,
    BCard,
    BCardBody,
    BCardHeader,
    BRow,
    BCol,
    vSelect,
  },
  props: {
    header: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    offerId: {
      type: String,
      default: '',
    },
    isDeal: {
      type: Boolean,
      default: false,
    },
    isOffer: {
      type: Boolean,
      default: false,
    },
    defaultCountry: {
      type: String,
      default: '',
    },
    countryId: {
      type: [String, Number],
      default: '',
    },
    currency: {
      type: [String, Number],
      default: USD_ID,
    },
    budget: {
      type: [String, Number],
      default: '',
    },
    lead: {
      type: [String, Number],
      default: '',
    },
  },
  data: () => ({
    currencyOptions: [
      ...currencyOptions,
    ],
  }),
  computed: {
    countryOptions() {
      const getCountries = this.$store.state.app.availableCountries;
      return getCountries.map(country => ({ value: country.id, label: country.name }));
    },
    currencySymbol() {
      return getCurrencySymbol(this.currency);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/@core/scss/base/core/colors/palette-variables.scss';

.divider {
  margin: 0;
}
.offer-id {
  font-weight: 500;
  color: $primary;
}
</style>
