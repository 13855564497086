<template>
  <b-card no-body>
    <b-card-header>
      <h5>
        {{ title }}
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          class="mt-1"
          cols="12"
          xl="7"
          md="7"
        >
          <div class="d-flex align-items-center mb-1">
            <flat-pickr
              v-model="localDate"
              placeholder="YYYY-MM-DD"
              class="form-control invoice-edit-input"
              @on-change="changeLocalDate"
            />
          </div>
        </b-col>
        <b-col
          class="d-flex align-items-center mt-1 pl-md-0"
          cols="12"
          xl="5"
          md="5"
        >
          <b-form-checkbox
            id="unlimited-checkbox"
            v-model="unlimited"
            @change="changeCheckboxValue"
          >
            {{ $t('unlimited') }}
          </b-form-checkbox>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BRow, BCol,
  BFormCheckbox,
} from 'bootstrap-vue';
import flatPickr from 'vue-flatpickr-component';

export default {
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    BRow,
    BCol,
    BFormCheckbox,
    flatPickr,
  },
  props: {
    date: {
      type: String,
      default: '',
    },
    title: {},
  },
  data: () => ({
    localDate: null,
    unlimited: false,
  }),
  created() {
    if (this.date) {
      this.localDate = this.date;
    } else {
      this.unlimited = true;
    }
  },
  methods: {
    changeLocalDate(value) {
      const localDate = typeof value === 'object' ? value[0] : value;

      if (this.unlimited && localDate) this.unlimited = false;

      return this.$emit('update', localDate ? new Date(new Date(localDate).setDate(new Date(localDate).getDate() + 1) - 1).toISOString() : null);
    },
    changeCheckboxValue(value) {
      if (value) {
        if (this.localDate) this.localDate = null;
      } else {
        const dateNow = new Date();
        dateNow.setHours(0);
        dateNow.setMinutes(0);
        dateNow.setSeconds(0);
        dateNow.setMilliseconds(0);

        this.localDate = new Date(dateNow.setDate(dateNow.getDate() + 7)).toISOString();
      }
    },
  },
};
</script>

<style lang="scss">
@import 'src/@core/scss/base/core/colors/palette-variables.scss';
@import 'src/@core/scss/vue/libs/vue-flatpicker.scss';
</style>
