<template>
  <div
    :class="{ 'ml-2 mb-2 mr-2 p-2': !dealPacking }"
    class="packing d-flex flex-column"
  >
    <h6
      v-if="!dealPacking"
      class="packing-title mb-2"
    >
      {{ $t('packing') }}
    </h6>
    <b-row>
      <b-col
        v-for="item in packing"
        :key="item.id"
        cols="12"
        md="6"
        lg="3"
      >
        <b-form-group
          :label="$t(item.label)"
          :label-for="`packing-${item.id}`"
        >
          <b-row class="d-flex align-items-center">
            <b-col cols="8">
              <b-form-input
                :id="`packing-${item.id}`"
                v-model="item.value"
                type="number"
                @change="updatePacking"
              />
            </b-col>
            <b-col
              cols="4"
              class="p-0"
            >
              <span>
                {{ $t(item.units) }}
              </span>
            </b-col>
          </b-row>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput,
} from 'bootstrap-vue';

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    product: {
      type: Object,
      default: () => {},
    },
    dealPacking: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    packing: [
      {
        label: 'qty', value: null, units: 'pcs', id: 'qty',
      },
      {
        label: 'netWeight', value: null, units: 'kgs', id: 'weight',
      },
      {
        label: 'grossWeight', value: null, units: 'kgs', id: 'gross-weight',
      },
      {
        label: 'Meas\' Mt', value: null, units: 'cuFt', id: 'meas',
      },
    ],
  }),
  created() {
    if (this.dealPacking) {
      const {
        packing_gross, packing_meas, packing_net, packing_qty,
      } = this.product;
      this.packing[0].value = packing_qty;
      this.packing[1].value = packing_net;
      this.packing[2].value = packing_gross;
      this.packing[3].value = packing_meas;
    }
  },
  watch: {
    product(value) {
      if (value) {
        const {
          packing_gross, packing_meas, packing_net, packing_qty,
        } = value;
        this.packing[0].value = packing_qty;
        this.packing[1].value = packing_net;
        this.packing[2].value = packing_gross;
        this.packing[3].value = packing_meas;
      }
    },
  },
  methods: {
    updatePacking() {
      const dataToUpdate = {
        packing_qty: this.packing.find(item => item.id === 'qty').value, // nullable
        packing_net: this.packing.find(item => item.id === 'weight').value, // nullable
        packing_gross: this.packing.find(item => item.id === 'gross-weight').value, // nullable
        packing_meas: this.packing.find(item => item.id === 'meas').value, // nullable
      };
      this.$emit('update', dataToUpdate);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../../@core/scss/base/core/colors/palette-variables';
@import '../../../../@core/scss/base/components/variables-dark';
@import "../../../../@core/scss/base/pages/app-invoice";

.packing {
  background-color:$product-details-bg;
  &-title {
    font-weight: 600;
  }
}
</style>

<style lang="scss">
@import '../../../../@core/scss/base/core/colors/palette-variables';
@import '../../../../@core/scss/base/components/variables-dark';
.dark-layout {
  .packing {
    background-color: $theme-dark-body-bg;
  }
}
</style>
